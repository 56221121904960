<template>
  <div class="orderDetails">
    <div class="modular_box">
      <div class="head">
        <div class="line"></div>
        <div class="title">基本信息</div>
      </div>

      <div class="details_Style">
        <div class="details_content">
          <div class="details_title">订单号：</div>
          <el-input class="detailsInput" v-model.trim="detailsList.surfaceNumber" disabled size="mini"></el-input>
        </div>
        <div class="details_content">
          <div class="details_title">原始单号：</div>
          <el-input class="detailsInput" v-model.trim="detailsList.originalNumber" disabled size="mini"></el-input>
        </div>
        <div class="details_content">
          <div class="details_title">承运商：</div>
          <el-input class="detailsInput" v-model.trim="detailsList.compName" disabled size="mini"></el-input>
        </div>
        <div class="details_content">
          <div class="details_title">状态：</div>
          <el-input class="detailsInput" v-model.trim="detailsList.status2Text" disabled size="mini"></el-input>
        </div>
        <div class="details_content">
          <div class="details_title">发货地址：</div>
          <span v-if="detailsList.shipmentsAddress">
            <el-input class="detailsInput" v-model="detailsList.shipmentsAddress.shippingAddress" disabled size="mini"></el-input>
          </span>
        </div>
        <div class="details_content">
          <div class="details_title">收货地址：</div>
          <span v-if="detailsList.arriveAddress">
            <el-input class="detailsInput" v-model="detailsList.arriveAddress.receiptAddress" disabled size="mini"></el-input>
          </span>
        </div>
        <div class="details_content">
          <div class="details_title">发货人：</div>
          <span v-if="detailsList.shipmentsAddress">
            <el-input class="detailsInput" v-model="detailsList.shipmentsAddress.userName" disabled size="mini"></el-input>
          </span>
        </div>
        <div class="details_content">
          <div class="details_title">收货人：</div>
          <span v-if="detailsList.arriveAddress">
            <el-input class="detailsInput" v-model="detailsList.arriveAddress.userName" disabled size="mini"></el-input>
          </span>
        </div>
        <div class="details_content">
          <div class="details_title">重量：</div>
          <span v-if="detailsList.cargo">
            <el-input class="detailsInput" v-model="detailsList.cargo[0].gdWeight" disabled size="mini">
              <template slot="append">kg</template>
            </el-input>
          </span>
        </div>
        <div class="details_content">
          <div class="details_title">体积：</div>
          <span v-if="detailsList.cargo">
            <el-input class="detailsInput" v-model="detailsList.cargo[0].gdVolume" disabled size="mini">
              <template slot="append">m3</template>
            </el-input>
          </span>
        </div>
        <div class="details_content">
          <div class="details_title">件数：</div>
          <span v-if="detailsList.cargo">
            <el-input class="detailsInput" v-model="detailsList.cargo[0].gdNumber" disabled size="mini">
              <template slot="append">件</template>
            </el-input>
          </span>
        </div>
        <div class="details_content">
          <div class="details_title">货物名称：</div>
          <span v-if="detailsList.cargo">
            <el-input class="detailsInput" v-model="detailsList.cargo[0].gdName" disabled size="mini"></el-input>
          </span>
        </div>
        <div class="details_content">
          <div class="details_title">货物类型：</div>
          <span v-if="detailsList.pathInfoObj">
            <el-input class="detailsInput" v-model="detailsList.pathInfoObj.goodsType" disabled size="mini"></el-input>
          </span>
        </div>
        <div class="details_content">
          <div class="details_title">时效类型：</div>
          <span v-if="detailsList.pathInfoObj">
            <el-input class="detailsInput" v-model="detailsList.pathInfoObj.speedType" disabled size="mini"></el-input>
          </span>
        </div>
        <div class="details_content">
          <div class="details_title">线路ID：</div>
          <span v-if="detailsList.pathInfoObj">
            <el-input class="detailsInput" v-model="detailsList.pathInfoObj.pathCode" disabled size="mini"></el-input>
          </span>
        </div>
      </div>
    </div>

    <div class="modular_box">
      <div class="head">
        <div class="line"></div>
        <div class="title">价格信息</div>
      </div>

      <div class="details_Style">
        <div class="details_content">
          <div class="details_title">预计价格之和：</div>
          <span v-if="detailsList.pathInfoObj">
            <el-input class="detailsInput" size="mini" disabled v-model="detailsList.estimatedPrice">
              <template slot="append">元</template>
            </el-input>
          </span>
        </div>
        <div class="details_content">
          <div class="details_title">物流费：</div> 
          <span v-if="detailsList.pathInfoObj">
            <el-input class="detailsInput" size="mini" disabled v-model="detailsList.pathInfoObj.price">
            <template slot="append">元</template>
          </el-input>
          </span>        
        </div>
        <div class="details_content">
          <div class="details_title">保价费：</div>
          <el-input class="detailsInput" size="mini" disabled v-model="detailsList.ext2">
            <template slot="append">元</template>
          </el-input>
        </div>
        <div class="details_content">
          <div class="details_title">实际价格之和：</div>
          <el-input class="detailsInput" size="mini" disabled v-model="detailsList.newPathPrice">
            <template slot="append">元</template>
          </el-input>
        </div>
      </div>
    </div>

    <div class="modular_box">
      <div class="head">
        <div class="line"></div>
        <div class="title">扫码信息</div>
      </div>

      <el-table :data="tableData" border style="width: 100%" :header-cell-style="{ 
      background:'#ebeef5',hight:'50px',fontSize:'12px'}">
        <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column prop="status_text2" label="操作" width="180" align="center"></el-table-column>
        <el-table-column prop="req_name" label="操作人" width="180" align="center"></el-table-column>
        <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
        <el-table-column prop="created_dt" label="操作时间" width="180" align="center"></el-table-column>
        <el-table-column prop="address" label="地址" width="180" align="center"></el-table-column>
      </el-table>
    </div>

    <div class="modular_box">
      <div class="head">
        <div class="line"></div>
        <div class="title">照片</div>
      </div>

      <div class="image_upload">
        <div v-for="(item,index) in tableData" :key="index">
          <el-image v-if="item.ext2" style="width: 100px; height: 100px" :src="item.ext2" :preview-src-list="[item.ext2]">
          </el-image>
        </div>

        <div class="image_title">回单</div>
      </div>
    </div>

    <div class="button_btn">
      <el-button type="button" size="small" @click="close">关 闭</el-button>
    </div>
  </div>
</template>

<script>
import { scanCode } from "@/api/basics";
// import utils from "@/plugin/utils/utils";
export default {
  data() {
    return {
      tableData: [],
      detailsList: {},
    };
  },
  mounted() {
    var obj = JSON.parse(this.$route.query.details);
    if (obj) {
      // 货物类型
      var goodsType, speedType, shippingAddress, receiptAddress;
      if (obj.pathInfoObj.goodsType) {
        if (obj.pathInfoObj.goodsType == 1) {
          goodsType = "普货";
        } else if (obj.pathInfoObj.goodsType == 2) {
          goodsType = "冷链";
        } else if (obj.pathInfoObj.goodsType == 3) {
          goodsType = "医药";
        } else {
          goodsType = "危化";
        }
      }
      //时效类型
      if (obj.pathInfoObj.speedType) {
        if (obj.pathInfoObj.speedType == 1) {
          speedType = "标准";
        } else if (obj.pathInfoObj.speedType == 2) {
          speedType = "普快";
        } else {
          speedType = "特快";
        }
      }

      //发货地址 Shipping Address
      console.log(obj.shipmentsAddress);
      console.log(obj.arriveAddress);
      if (obj.shipmentsAddress) {
        var arr = obj.shipmentsAddress;
        if (arr.area) {
          if (arr.address) {
            shippingAddress = arr.province + arr.city + arr.area + arr.address;
          } else {
            shippingAddress = arr.province + arr.city + arr.area;
          }
        } else {
          if (arr.address) {
            shippingAddress = arr.province + arr.city + arr.address;
          } else {
            shippingAddress = arr.province + arr.city;
          }
        }
      }

      if (obj.arriveAddress) {
        var arr2 = obj.arriveAddress;
         if (arr2.area) {
          if (arr2.address) {
            receiptAddress = arr2.province + arr2.city + arr2.area + arr2.address;
          } else {
            receiptAddress = arr2.province + arr2.city + arr2.area;
          }
        } else {
          if (arr.address) {
            receiptAddress = arr2.province + arr2.city + arr2.address;
          } else {
            receiptAddress = arr2.province + arr2.city;
          }
        }
      }

      obj.pathInfoObj.goodsType = goodsType;
      obj.pathInfoObj.speedType = speedType;
      obj.shipmentsAddress.shippingAddress = shippingAddress;
      obj.arriveAddress.receiptAddress = receiptAddress;

      //ext2 保价费
      if (obj.ext2) {
        obj.estimatedPrice = Number(obj.pathInfoObj.price) + Number(obj.ext2); //预计价格之和
        //判断物流费等于实际价格 实际价格之和 = 物流费+保价费  否则实际价格 = 物流费
        // if(Number(obj.newPathPrice)  == Number(obj.pathInfoObj.price)) {
        //   obj.actualPrice = Number(obj.newPathPrice) + Number(obj.ext2); //实际价格之和
        // }else {
        //   obj.actualPrice = obj.newPathPrice;
        // }    
      } else {
        obj.estimatedPrice = Number(obj.pathInfoObj.price) + 0; //预计价格之和
        // obj.actualPrice = Number(obj.newPathPrice) + 0; //实际价格之和
      }

      this.detailsList = obj;
      // console.log(this.detailsList, "detailsList");
      this.scanningCode();
    }
  },
  methods: {
    async scanningCode() {
      try {
        let data = {
          ext1: this.detailsList.sid,
          // ext1: "2022101910541745300020105",
        };
        let res = await scanCode(data);
        console.log(res);
        if (res.retCode == "0000000") {
          console.log(res, "res");
          this.tableData = res.rspBody;
        } else {
          this.$message({
            type: "error",
            message: res.retDesc,
          });
        }
      } catch (err) {
        console.log(err);
        this.$message({
          type: "error",
          message: err.retDesc,
        });
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      console.log(file);
    },
    close() {
      this.$router.push({
        path: "/order/order",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.orderDetails {
  height: 100%;
  width: 100%;
  // position: relative;

  .image_upload {
    width: 150px;
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    // flex-wrap: wrap;
    .image_title {
      margin: 0 auto;
      padding-top: 5px;
    }
  }
  .button_btn {
    position: absolute;
    bottom: 0;
    left: 232px;
    right: 0;
    padding: 10px 0;
  }
}
</style>